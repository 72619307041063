import Header from "./Header.js";
import Footer from "./Footer.js";
import BackToTopButton from "../BackToTop";

function Educatie() {
    return (<>
        <Header />
        <center>
            <div>
                <center>
                    <h1 style={{ marginInline: '11%', marginTop: 30, textAlign: 'center', fontSize: 60 }}>Conferințe</h1>
                </center>

                <div class="row" style={{ marginLeft: '5vw', marginRight: '5vw', marginTop: '3vw' }}>

                    <div class="col-md-4 col-sm-8">
                        <center>
                            <a href="https://form.123formbuilder.com/6657192/inscriere-conferinte-smartup-2024" target="_blank" rel="noopener noreferrer">
                                <img src="./evenimente/ovidiu.png" alt='banana' class='img-sectiune' />
                            </a>
                        </center>
                    </div>

                    <div class="col-md-4 col-sm-8">
                        <center>
                            <a href="https://form.123formbuilder.com/6657192/inscriere-conferinte-smartup-2024" target="_blank" rel="noopener noreferrer">
                                <img src="./evenimente/ina.png" alt='banana' class='img-sectiune' />
                            </a>
                        </center>
                    </div>

                    <div class="col-md-4 col-sm-8">
                        <center>
                            <a href="https://form.123formbuilder.com/6657192/inscriere-conferinte-smartup-2024" target="_blank" rel="noopener noreferrer">
                                <img src="./evenimente/Norvis.png" alt='banana' class='img-sectiune' />
                            </a>
                        </center>
                    </div>
                    <div class="col-md-4 col-sm-8">
                        <center>
                            <a href="https://form.123formbuilder.com/6657192/inscriere-conferinte-smartup-2024" target="_blank" rel="noopener noreferrer">
                                <img src="./evenimente/gmp_conf.png" alt='banana' class='img-sectiune' />
                            </a>
                        </center>
                    </div>

                </div>
            </div>
        </center>

        <center>
            <div style={{ marginTop: '5vw' }}>
                <center>
                    <h1 style={{ marginInline: '11%', marginTop: 30, textAlign: 'center', fontSize: 60 }}>Simulări de interviuri</h1>
                </center>


                <div class="row" style={{ marginLeft: '5vw', marginRight: '5vw', marginTop: '3vw' }}>

                    <div class="col-md-3 col-sm-6">
                        <center>
                            <a href="https://form.123formbuilder.com/6656313/inscriere-interviuri-smartup-2024" target="_blank" rel="noopener noreferrer">
                                <img src="./parteneri/Access_1.png" alt='banana' class='img-sectiune' />
                            </a>
                        </center>
                    </div>

                    <div class="col-md-3 col-sm-6">
                        <center>
                            <a href="https://form.123formbuilder.com/6656313/inscriere-interviuri-smartup-2024" target="_blank" rel="noopener noreferrer">
                                <img src="./parteneri/Adecco_1.png" alt='banana' class='img-sectiune' />
                            </a>
                        </center>
                    </div>

                    <div class="col-md-3 col-sm-6">
                        <center>
                            <a href="https://form.123formbuilder.com/6656313/inscriere-interviuri-smartup-2024" target="_blank" rel="noopener noreferrer">
                                <img src="./parteneri/Amdaris_1.png" alt='banana' class='img-sectiune' />
                            </a>
                        </center>
                    </div>

                    <div class="col-md-3 col-sm-6">
                        <center>
                            <a href="https://form.123formbuilder.com/6656313/inscriere-interviuri-smartup-2024" target="_blank" rel="noopener noreferrer">
                                <img src="./parteneri/Atos_1.png" alt='banana' class='img-sectiune' />
                            </a>
                        </center>
                    </div>

                    <div class="col-md-3 col-sm-6">
                        <center>
                            <a href="https://form.123formbuilder.com/6656313/inscriere-interviuri-smartup-2024" target="_blank" rel="noopener noreferrer">
                                <img src="./parteneri/Autonom_1.png" alt='banana' class='img-sectiune' />
                            </a>
                        </center>
                    </div>

                    <div class="col-md-3 col-sm-6">
                        <center>
                            <a href="https://form.123formbuilder.com/6656313/inscriere-interviuri-smartup-2024" target="_blank" rel="noopener noreferrer">
                                <img src="./parteneri/Conti_1.png" alt='banana' class='img-sectiune' />
                            </a>
                        </center>
                    </div>

                    <div class="col-md-3 col-sm-6">
                        <center>
                            <a href="https://form.123formbuilder.com/6656313/inscriere-interviuri-smartup-2024" target="_blank" rel="noopener noreferrer">
                                <img src="./parteneri/Deloitte_1.png" alt='banana' class='img-sectiune' />
                            </a>
                        </center>
                    </div>

                    <div class="col-md-3 col-sm-6">
                        <center>
                            <a href="https://form.123formbuilder.com/6656313/inscriere-interviuri-smartup-2024" target="_blank" rel="noopener noreferrer">
                                <img src="./parteneri/Hamilton_1.png" alt='banana' class='img-sectiune' />
                            </a>
                        </center>
                    </div>

                    <div class="col-md-3 col-sm-6">
                        <center>
                            <a href="https://form.123formbuilder.com/6656313/inscriere-interviuri-smartup-2024" target="_blank" rel="noopener noreferrer">
                                <img src="./parteneri/haufegroup_1.png" alt='banana' class='img-sectiune' />
                            </a>
                        </center>
                    </div>

                    <div class="col-md-3 col-sm-6">
                        <center>
                            <a href="https://form.123formbuilder.com/6656313/inscriere-interviuri-smartup-2024" target="_blank" rel="noopener noreferrer">
                                <img src="./parteneri/LifeInJobs_1.png" alt='banana' class='img-sectiune' />
                            </a>
                        </center>
                    </div>

                    <div class="col-md-3 col-sm-6">
                        <center>
                            <a href="https://form.123formbuilder.com/6656313/inscriere-interviuri-smartup-2024" target="_blank" rel="noopener noreferrer">
                                <img src="./parteneri/MSG_1.png" alt='banana' class='img-sectiune' />
                            </a>
                        </center>
                    </div>

                    <div class="col-md-3 col-sm-6">
                        <center>
                            <a href="https://form.123formbuilder.com/6656313/inscriere-interviuri-smartup-2024" target="_blank" rel="noopener noreferrer">
                                <img src="./parteneri/TE conectivity_1.png" alt='banana' class='img-sectiune' />
                            </a>
                        </center>
                    </div>

                    <div class="col-md-3 col-sm-6">
                        <center>
                            <a href="https://form.123formbuilder.com/6656313/inscriere-interviuri-smartup-2024" target="_blank" rel="noopener noreferrer">
                                <img src="./parteneri/UniCredit_1.png" alt='banana' class='img-sectiune' />
                            </a>
                        </center>
                    </div>

                </div>


            </div>
        </center>

        <BackToTopButton />
        <Footer />

    </>);
}

export default Educatie;