import Header from "./Header.js";
import Footer from "./Footer.js";
import BackToTopButton from "../BackToTop.js";

function Evenimente() {
    return (<>
        <Header />
        <div>
            <center><h1 style={{ marginInline: '11%', marginTop: 30, textAlign: 'center', fontSize: 60 }}>
                Oportunități de job
            </h1>
            </center>
            <div class="row" style={{ marginLeft: '5vw', marginRight: '5vw', marginTop: '3vw' }}>

                <div class="col-md-4 col-sm-12">
                    <center>
                        <a href="https://drive.google.com/file/d/1icOmRAvhP-0_9-797dznEz1m8YfD4QGA/view?usp=drive_link" target="_blank" rel="noopener noreferrer">
                            <img src="./jobs/atos.png" alt='banana' class='img-sectiune' />
                        </a>
                    </center>
                </div>

                <div class="col-md-4 col-sm-12">
                    <center>
                        <a href="https://www.autonom.ro/cariere/job/agent-servicii-client/timisoara" target="_blank" rel="noopener noreferrer">
                            <img src="./jobs/Autonom.png" alt='banana' class='img-sectiune' />
                        </a>
                    </center>
                </div>

                <div class="col-md-4 col-sm-12">
                    <center>
                        <a href="https://docs.google.com/document/d/1hfJyKL_LKd-kYzyBZmmyei28jqJd85xnrZoUnl39wNQ/edit" target="_blank" rel="noopener noreferrer">
                            <img src="./jobs/LifeInJobs.png" alt='banana' class='img-sectiune' />
                        </a>
                    </center>
                </div>

                <div class="col-md-4 col-sm-12">
                    <center>
                        <a href="https://docs.google.com/document/d/1PoWvDtCveAj0GMzedZ_0ZW6IXCYiIvLm/edit?usp=drive_link&ouid=101544133094313532666&rtpof=true&sd=true" target="_blank" rel="noopener noreferrer">
                            <img src="./jobs/unicredit_5.png" alt='banana' class='img-sectiune' />
                        </a>
                    </center>
                </div>
            </div>
        </div>
        <BackToTopButton />
        <Footer />
    </>);
}

export default Evenimente;